<template>
  <div>
    <CCard v-if="view == 'table'">
      <CCardHeader>
        <strong>Filtrar </strong>
      </CCardHeader>
      <CCardBody>
        <CRow>
          <CCol sm="3">
            <CInput type="date" label="Desde" v-model="search.date_initial">
            </CInput>
          </CCol>
          <CCol sm="3">
            <CInput
              type="date"
              label="Hasta"
              :min="search.date_initial"
              v-model="search.date_end"
              :max="hoy"
            >
            </CInput>
          </CCol>
          <CCol sm="1">
            <label for="">&nbsp;</label>
            <br />
            <CButton color="info" class="w-100" @click="mtd_search_filter()">
              <i class="fas fa-search"></i>
            </CButton>
          </CCol>
          <CCol lg="3" md="3" xs="12" sm="12">
            <label for="">&nbsp;</label>
            <br />
            <CButton
              @click="mtd_download"
              :disabled="data.length == 0 ? true : false"
              color="success"
              class="w-100"
              ><i class="fas fa-file-excel"></i> Cotizaciones</CButton
            >
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
    <CRow v-if="view == 'table'">
      <CCol sm="12">
        <CTableWrapper
          :items="data"
          :fields="fields"
          hover
          :striped="true"
          :border="true"
          small
          fixed
          caption="Lista de cotizaciones"
          icon="fas fa-edit"
          btn_name="cotización"
          :size="'md'"
          :button_new="true"
          :actions="true"
          :withActions="'20%'"
          :buttonEdit="false"
          :buttonDelete="true"
          :myButtons="myButtons"
          @show_modal="mtd_set_document"
          @mtd_edit="mtd_edit"
          @action_delete="mtd_delete"
          @mtd_view_det="mtd_view_det"
          @mtd_add_coti="mtd_add_coti"
          @mtd_orden_compra="mtd_orden_compra"
        />
      </CCol>
    </CRow>
    <CRow v-else-if="view == 'document'">
      <CCol lg="6" sm="6" md="12" xs="12" class="text-left">
        <CButton size="sm" color="warning" @click="mtd_return_table">
          <i class="fas fa-angle-left"></i> Regresar
        </CButton>
        <CButton size="sm" class="ml-2" color="success" @click="mtd_add_item">
          <i class="fas fa-plus"></i> Agregar fila
        </CButton>
        <CButton size="sm" class="ml-2" color="info" @click="mtd_upload_excel">
          <i class="fas fa-upload"></i> Cargar detalle
        </CButton>
      </CCol>
      <CCol lg="6" sm="6" md="12" xs="12" class="text-right">
        <CButton
          size="sm"
          color="info"
          v-c-tooltip.hover="{
            content: action == 'edit' ? 'Editar' : 'Registrar',
          }"
          @click="mtd_save"
          :disabled="cp_disabled_save"
        >
          <i :class="action == 'edit' ? 'fas fa-edit' : 'fas fa-save'"></i>
          {{ action == "edit" ? "Editar" : "Registrar" }}
        </CButton>
      </CCol>
      <CCol class="mt-2" lg="12" sm="12" md="12" xs="12">
        <CCard>
          <CCardHeader>
            <strong> Cotización</strong>
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol lg="6" md="6" sm="12" xs="12" v-if="action != 'edit'">
                <label for="">Cliente</label>
                <v-select
                  :options="data_client"
                  v-model="coti.client"
                  placeholder="Seleccione cliente"
                />
              </CCol>
              <CCol lg="2" md="2" sm="6" xs="12" v-if="action == 'edit'">
                <CInput
                  label="Cotización"
                  placeholder="Cotización"
                  v-model="coti.coti"
                />
              </CCol>
              <CCol lg="2" md="2" sm="6" xs="12">
                <CInput
                  label="Ticket"
                  placeholder="Ticket"
                  v-model="coti.ticket"
                />
              </CCol>
              <CCol lg="4" md="4" sm="6" xs="12">
                <CInput
                  label="Encargado"
                  placeholder="Encargado"
                  v-model="coti.tecnico"
                />
              </CCol>
              <CCol lg="12" md="12" sm="12" xs="12">
                <CInput
                  label="Observación"
                  placeholder="Observación"
                  v-model="coti.observation"
                />
              </CCol>
            </CRow>
            <hr />
            <CRow>
              <CCol
                lg="12"
                md="12"
                sm="12"
                xs="12"
                class="position-relative table-responsive"
              >
                <table class="table table-striped table-bordered">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th width="15%">Descripción</th>
                      <th width="6%">Uni.</th>
                      <th width="15%">Proveedor</th>
                      <!-- <th>Factura</th> -->
                      <th width="5%">Cant.</th>
                      <th width="6%">Cst.To.</th>
                      <th width="6%">Cst. S/I</th>
                      <th width="7%" class="text-center">%</th>
                      <th width="7%">Pre.Vnt.</th>
                      <th width="7%">Venta</th>
                      <th width="7%">Costo</th>
                      <th width="7%">Ganancia</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody v-if="coti.detalle.length > 0">
                    <tr v-for="(item, index) in coti.detalle" :key="index">
                      <td class="m-0 pb-0">{{ index + 1 }}</td>
                      <td class="m-0 pb-0">
                        <CInput
                          label=""
                          placeholder="Descripción"
                          v-model="item.description"
                          size="sm"
                        />
                      </td>
                      <td class="m-0 pb-0">
                        <CInput
                          label=""
                          placeholder="Uni."
                          v-model="item.unit"
                          size="sm"
                        />
                      </td>
                      <td class="m-0 pb-0">
                        <CInput
                          label=""
                          placeholder="Proveedor"
                          v-model="item.proveedor"
                          size="sm"
                        />
                      </td>
                      <!-- <td class="m-0 pb-0">
                        <CInput
                          label=""
                          placeholder="Factura"
                          v-model="item.factura"
                          size="sm"
                        />
                      </td> -->
                      <td class="m-0 pb-0">
                        <CInput
                          label=""
                          placeholder="Cant."
                          v-model="item.quantity"
                          size="sm"
                          @keyup="mtd_out_focus"
                          v-on:keypress="isNumber($event)"
                        />
                      </td>
                      <td class="m-0 pb-0">
                        <CInput
                          label=""
                          placeholder="Cst.To."
                          v-model="item.costo"
                          @keyup="mtd_out_focus"
                          v-on:keypress="isNumber($event)"
                          size="sm"
                        />
                      </td>
                      <td class="m-0 pb-0">
                        <CInput
                          label=""
                          placeholder="Costo S/I"
                          v-model="item.cost_sin_igv"
                          size="sm"
                          :disabled="true"
                        />
                      </td>
                      <td class="m-0 pb-0">
                        <CInput
                          label=""
                          placeholder="%"
                          v-model="item.porcentaje"
                          @keyup="mtd_out_focus"
                          v-on:keypress="isNumber($event)"
                          size="sm"
                        />
                      </td>
                      <td class="m-0 pb-0">
                        <CInput
                          label=""
                          placeholder="Precio venta"
                          v-model="item.price_venta"
                          size="sm"
                          :disabled="true"
                        />
                      </td>
                      <td class="text-left m-0 pb-0">
                        <CInput
                          label=""
                          placeholder="Total venta"
                          v-model="item.subtotal_venta"
                          :disabled="true"
                          size="sm"
                        />
                      </td>
                      <td class="text-left m-0 pb-0">
                        <CInput
                          label=""
                          placeholder="Total costo"
                          v-model="item.subtotal_costo"
                          :disabled="true"
                          size="sm"
                        />
                      </td>
                      <td class="text-left m-0 pb-0">
                        <CInput
                          label=""
                          placeholder="Total ganancia"
                          v-model="item.subtotal_ganancia"
                          :disabled="true"
                          size="sm"
                        />
                      </td>
                      <td class="m-0 pb-0">
                        <CButton
                          size="sm"
                          color="danger"
                          v-c-tooltip.hover="{
                            content: 'Eliminar',
                          }"
                          @click="mtd_del_item(index)"
                        >
                          <i class="fas fa-trash"></i>
                        </CButton>
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td :colspan="13">
                        <slot name="no-items-view">
                          <div class="text-center">
                            <h2>No hay registros para mostrar</h2>
                          </div>
                        </slot>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colspan="9" class="text-right">
                        <strong>SUBTOTAL</strong>
                      </td>
                      <td class="text-right">
                        {{ parseFloat(sub_venta).toFixed(2) }}
                      </td>
                      <td class="text-right">
                        {{ parseFloat(sub_costo).toFixed(2) }}
                      </td>
                      <td class="text-right">
                        {{ parseFloat(sub_gana).toFixed(2) }}
                      </td>
                    </tr>
                    <tr>
                      <td colspan="9" class="text-right">
                        <strong>IGV</strong>
                      </td>
                      <td class="text-right">
                        {{ parseFloat(sub_venta * 0.18).toFixed(2) }}
                      </td>
                      <td class="text-right">
                        {{ parseFloat(sub_costo * 0.18).toFixed(2) }}
                      </td>
                      <td class="text-right">
                        {{ parseFloat(sub_gana * 0.18).toFixed(2) }}
                      </td>
                    </tr>
                    <tr>
                      <td colspan="9" class="text-right">
                        <strong>TOTAL</strong>
                      </td>
                      <td class="text-right">
                        {{ parseFloat(sub_venta * 1.18).toFixed(2) }}
                      </td>
                      <td class="text-right">
                        {{ parseFloat(sub_costo * 1.18).toFixed(2) }}
                      </td>
                      <td class="text-right">
                        {{ parseFloat(sub_gana * 1.18).toFixed(2) }}
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
      <CCol lg="6" sm="6" md="12" xs="12" class="text-left">
        <CButton size="sm" color="warning" @click="mtd_return_table">
          <i class="fas fa-angle-left"></i> Regresar
        </CButton>
        <CButton size="sm" class="ml-2" color="success" @click="mtd_add_item">
          <i class="fas fa-plus"></i> Agregar fila
        </CButton>
        <CButton size="sm" class="ml-2" color="info" @click="mtd_upload_excel">
          <i class="fas fa-upload"></i> Cargar detalle
        </CButton>
      </CCol>
      <CCol
        :lg="action == 'edit' ? 12 : 6"
        :sm="action == 'edit' ? 12 : 6"
        md="12"
        xs="12"
        class="text-right mb-2"
      >
        <CButton
          size="sm"
          color="info"
          v-c-tooltip.hover="{
            content: action == 'edit' ? 'Editar' : 'Registrar',
          }"
          @click="mtd_save"
          :disabled="cp_disabled_save"
        >
          <i :class="action == 'edit' ? 'fas fa-edit' : 'fas fa-save'"></i>
          {{ action == "edit" ? "Editar" : "Registrar" }}
        </CButton>
      </CCol>
    </CRow>
    <CRow v-else>
      <CCol lg="3" sm="3" md="12" xs="12" class="text-left">
        <CButton size="sm" color="warning" @click="mtd_return_table">
          <i class="fas fa-angle-left"></i> Regresar
        </CButton>
      </CCol>
      <CCol lg="2" sm="2" md="12" xs="12" class="text-center mb-2">
        <button
          class="btn btn-sm btn-success d-flex align-items-center"
          type="button"
        >
          <strong>
            <span class="ml-2">Costo: {{ cp_total_costo }}</span></strong
          >
        </button>
      </CCol>
      <CCol lg="2" sm="2" md="12" xs="12" class="text-center mb-2">
        <button
          class="btn btn-sm btn-success d-flex align-items-center"
          type="button"
        >
          <strong>
            <span class="ml-2">Ganancia: {{ cp_total_ganancia }}</span></strong
          >
        </button>
      </CCol>
      <CCol lg="2" sm="2" md="12" xs="12" class="text-center mb-2">
        <button
          class="btn btn-sm btn-success d-flex align-items-center"
          type="button"
        >
          <strong>
            <span class="ml-2">Venta: {{ cp_total_venta }}</span></strong
          >
        </button>
      </CCol>
      <CCol lg="3" sm="3" md="12" xs="12" class="text-right">
        <CButton
          size="sm"
          color="info"
          v-c-tooltip.hover="{
            content: 'Registrar documento',
          }"
          @click="mtd_save_oc"
          :disabled="cp_disabled_save_oc"
        >
          <i class="fas fa-save"></i> Registrar
        </CButton>
      </CCol>
      <CCol class="mt-2" lg="12" sm="12" md="12" xs="12">
        <CCard>
          <CCardHeader>
            <strong> Orden de compra</strong>
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol lg="4" md="4" sm="12" xs="12">
                <CInput
                  label="N° Orden compra"
                  placeholder="N° Orden compra"
                  v-model="orden.number"
                />
              </CCol>
              <CCol lg="4" md="4" sm="12" xs="12">
                <CInput label="Guia" placeholder="Guia" v-model="orden.guia" />
              </CCol>
              <CCol lg="4" md="4" sm="12" xs="12">
                <CInput
                  type="date"
                  label="Fecha Orden compra"
                  v-model="orden.date"
                />
              </CCol>
              <CCol
                lg="12"
                md="12"
                sm="12"
                xs="12"
                class="position-relative table-responsive"
              >
                <table class="table table-striped table-bordered">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Producto</th>
                      <th>Unidad</th>
                      <th>Proveedor</th>
                      <th>Cantidad</th>
                      <th>Costo</th>
                      <th>Costo S/I</th>
                      <th>%</th>
                      <th>Pre. Venta</th>
                      <th>Venta</th>
                      <th>Costo</th>
                      <th>Ganancia</th>
                    </tr>
                  </thead>
                  <tbody v-if="orden_detail.length > 0">
                    <tr v-for="(it, index) in orden_detail" :key="index">
                      <td style="text-align: center; vertical-align: middle">
                        <input
                          type="checkbox"
                          @change="mtd_select_item(it)"
                          :id="'move_' + index"
                          :value="'move_' + index"
                        />
                      </td>
                      <td>{{ it.description }}</td>
                      <td>{{ it.unit }}</td>
                      <td>{{ it.proveedor }}</td>
                      <td>{{ it.quantity }}</td>
                      <td>{{ it.costo }}</td>
                      <td>{{ it.cost_sin_igv }}</td>
                      <td>{{ it.porcentaje }}</td>
                      <td>{{ it.price_venta }}</td>
                      <td>{{ parseFloat(it.subtotal_venta).toFixed(2) }}</td>
                      <td>{{ parseFloat(it.subtotal_costo).toFixed(2) }}</td>
                      <td>{{ parseFloat(it.subtotal_ganancia).toFixed(2) }}</td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td :colspan="12">
                        <slot name="no-items-view">
                          <div class="text-center">
                            <h2>No hay registros para mostrar</h2>
                          </div>
                        </slot>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
      <CCol lg="3" sm="3" md="12" xs="12" class="text-left mb-2">
        <CButton size="sm" color="warning" @click="mtd_return_table">
          <i class="fas fa-angle-left"></i> Regresar
        </CButton>
      </CCol>
      <CCol lg="2" sm="2" md="12" xs="12" class="text-center mb-2">
        <button
          class="btn btn-sm btn-success d-flex align-items-center"
          type="button"
        >
          <strong>
            <span class="ml-2">Costo: {{ cp_total_costo }}</span></strong
          >
        </button>
      </CCol>
      <CCol lg="2" sm="2" md="12" xs="12" class="text-center mb-2">
        <button
          class="btn btn-sm btn-success d-flex align-items-center"
          type="button"
        >
          <strong>
            <span class="ml-2">Ganancia: {{ cp_total_ganancia }}</span></strong
          >
        </button>
      </CCol>
      <CCol lg="2" sm="2" md="12" xs="12" class="text-center mb-2">
        <button
          class="btn btn-sm btn-success d-flex align-items-center"
          type="button"
        >
          <strong>
            <span class="ml-2">Venta: {{ cp_total_venta }}</span></strong
          >
        </button>
      </CCol>
      <CCol lg="3" sm="3" md="12" xs="12" class="text-right mb-2">
        <CButton
          size="sm"
          color="info"
          v-c-tooltip.hover="{
            content: 'Registrar documento',
          }"
          @click="mtd_save_oc"
          :disabled="cp_disabled_save_oc"
        >
          <i class="fas fa-save"></i> Registrar
        </CButton>
      </CCol>
    </CRow>

    <!-- modal delete -->
    <cModalDelete
      title="Borrar Compra"
      :boo_modal="modal_delete.boo"
      :item="modal_delete.item"
      @close_delete="close_delete"
      @mtd_commit="mtd_commit"
    ></cModalDelete>

    <!-- modal view -->
    <cModalViewDet
      title="Detalle"
      :boo_modal="modal_view.boo"
      :item="modal_view.item"
      :obs="modal_view.obs"
      :enc="modal_view.enc"
      :coti="modal_view.coti"
      @close_view="close_view"
    ></cModalViewDet>

    <!-- modal reg num coti -->
    <cModalRegCoti
      title="Detalle"
      :boo_modal="modal_regcoti.boo"
      :item="modal_regcoti.item"
      @close_regcoti="close_regcoti"
      @mtd_commit_regcoti="mtd_commit_regcoti"
    ></cModalRegCoti>

    <!-- modal reg num coti -->
    <cModalExcel
      title="Subir detalle"
      :boo_modal="modal_excel.boo"
      @close_upexcel="close_upexcel"
      @mtd_commit_upexcel="mtd_commit_upexcel"
    ></cModalExcel>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { bus } from "../../main";

import cModalDelete from "../../components/shared/modals/cModalDelete.vue";
import CTableWrapper from "../../components/shared/datatable/Table.vue";
import cModalViewDet from "../../components/shared/process/cModalViewDet.vue";
import cModalRegCoti from "../../components/shared/process/cModalRegCoti.vue";
import cModalExcel from "../../components/shared/process/cModalExcel.vue";

import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

import exportFromJson from "export-from-json";

const fields = [
  { key: "Id", _style: "width:3%" },
  { key: "client", label: "Cliente", _style: "width:10%;" },
  { key: "user", label: "Usuario reg.", _style: "width:10%;" },
  { key: "date", label: "Fecha", _style: "width:6%;" },
  { key: "venta", label: "Venta", _style: "width:5%;" },
  { key: "costo", label: "Costo", _style: "width:5%;" },
  { key: "ganancia", label: "Ganancia", _style: "width:5%;" },
  { key: "observation", label: "Observación", _style: "width:15%;" },
  { key: "tecnico", label: "Técnico", _style: "width:10%;" },
  { key: "coti", label: "Cotización", _style: "width:10%;" },
];

export default {
  name: "v-sale-document",
  components: {
    CTableWrapper,
    vSelect,
    cModalDelete,
    cModalViewDet,
    cModalRegCoti,
    cModalExcel,
  },
  data() {
    return {
      prefix: "proccess/coti",
      view: "table",
      fields,
      hoy: null,
      /** filtro */
      search: {
        date_initial: null,
        date_end: null,
      },
      /** */
      data_client: [],
      data: [],
      excel: [],
      action: null,
      myButtons: [
        {
          class: "btn-sm ml-1 text-center",
          color: "success",
          tooltip: "Editar",
          action: "mtd_edit",
          icon: "fas fa-edit",
        },
        {
          class: "btn-sm ml-1 text-center",
          color: "info",
          tooltip: "Ver Detalle",
          action: "mtd_view_det",
          icon: "fas fa-eye",
        },
        {
          class: "btn-sm ml-1 text-center",
          color: "warning",
          tooltip: "Reg. Num. Coti",
          action: "mtd_add_coti",
          icon: "fas fa-plus",
          show: {
            1: true,
            2: false,
          },
        },
        {
          class: "btn-sm ml-1 text-center",
          color: "success",
          tooltip: "Orden Compra",
          action: "mtd_orden_compra",
          icon: "fas fa-save",
          show: {
            1: false,
            2: true,
          },
        },
      ],
      coti: {
        client: null,
        ticket: "",
        tecnico: "",
        observation: "",
        subtotal_venta: "",
        subtotal_costo: "",
        subtotal_ganancia: "",
        coti: "",
        guia: "",
        detalle: [],
      },
      sub_venta: 0,
      sub_costo: 0,
      sub_gana: 0,
      /** modal delete */
      modal_delete: {
        boo: false,
        item: [],
      },
      /** modal detración */
      modal_view: {
        boo: false,
        item: [],
        obs: "",
        enc: "",
        coti: "",
      },
      /** modal reg coti */
      modal_regcoti: {
        boo: false,
        item: [],
      },
      /** orden compra */
      orden_detail: [],
      action_selection: [],
      orden: {
        quote_id: null,
        number: "",
        guia: "",
        date: null,
      },
      /** modal excel */
      modal_excel: {
        boo: false,
      },
      /** edit */
      action: null,
      coti_edit: null,
    };
  },
  computed: {
    cp_disabled_save: function () {
      //   if (this.document.description.length > 0 && this.document.total_tc > 0)
      //     return false;
      return false;
    },
    /** OC */
    cp_total_venta() {
      let res = 0;
      this.action_selection.forEach((element) => {
        res = parseFloat(res) + parseFloat(element.subtotal_venta);
      });
      return parseFloat(res * 1.18).toFixed(2);
    },
    cp_total_costo() {
      let res = 0;
      this.action_selection.forEach((element) => {
        res = parseFloat(res) + parseFloat(element.subtotal_costo);
      });
      return parseFloat(res * 1.18).toFixed(2);
    },
    cp_total_ganancia() {
      let res = 0;
      this.action_selection.forEach((element) => {
        res = parseFloat(res) + parseFloat(element.subtotal_ganancia);
      });
      return parseFloat(res * 1.18).toFixed(2);
    },
    cp_disabled_save_oc() {
      if (
        this.action_selection.length > 0 &&
        this.orden.number != "" &&
        this.orden.number != null
      )
        return false;
      return true;
    },
  },
  created() {
    let actual = new Date();
    let fecha;
    fecha =
      actual.getFullYear() +
      "-" +
      ("0" + (actual.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + actual.getDate()).slice(-2);
    this.hoy = fecha;
    this.search.date_initial = fecha;
    this.search.date_end = fecha;
    this.mtd_getData();
  },
  methods: {
    ...mapActions(["get", "post"]),
    mtd_getData: function () {
      this.get({
        url: this.$store.getters.get__url + "/" + this.prefix,
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          // console.log(response);
          // this.data = response.data;
          this.data_client = response.data_client;
        })
        .catch((errors) => {});
    },
    mtd_search_filter: function () {
      this.post({
        url: this.$store.getters.get__url + "/" + this.prefix,
        token: this.$store.getters.get__token,
        params: this.search,
      })
        .then((response) => {
          this.data = response.data;
          this.data.forEach((element) => {
            let det = element.detail;
            console.log(det);
            det.forEach(ele => {
              this.excel.push({
                Cliente: element.client,
                "Usuario Registra": element.user,
                Fecha: element.date,
                'Venta Total': element.venta,
                'Costo Total': element.costo,
                'Ganancia Total': element.ganancia,
                Observación: element.observation,
                Cotización: element.coti,
                Encargado: element.tecnico,
                Ticket: element.ticket == null ? "-" : element.ticket,
                Producto:ele.description,
                Unidad:ele.unit,
                Proveedor:ele.proveedor,
                Cantidad:ele.quantity,
                Costo:ele.costo,
                'Costo S/I':ele.cost_sin_igv,
                '%':ele.porcentaje,
                'Pre. Venta':ele.price_venta,
                Venta:parseFloat(ele.subtotal_venta).toFixed(2),
                Costo:parseFloat(ele.subtotal_costo).toFixed(2),
                Ganancia:parseFloat(ele.subtotal_ganancia).toFixed(2),
              });
            });
          });
        })
        .catch((errors) => {});
    },
    mtd_set_document: function (item) {
      this.view = "document";
      this.action = null;
      this.coti = {
        client: null,
        ticket: "",
        tecnico: "",
        observation: "",
        subtotal_venta: "",
        subtotal_costo: "",
        subtotal_ganancia: "",
        coti: "",
        guia: "",
        detalle: [],
      };
    },
    mtd_return_table: function () {
      this.view = "table";
      this.coti = {
        client: null,
        ticket: "",
        tecnico: "",
        observation: "",
        subtotal_venta: "",
        subtotal_costo: "",
        subtotal_ganancia: "",
        coti: "",
        guia: "",
        detalle: [],
      };
      this.action_selection = [];
      this.orden = {
        quote_id: null,
        number: "",
        guia: "",
        date: null,
      };
    },
    mtd_add_item: function () {
      this.coti.detalle.push({
        description: "",
        unit: "UN",
        proveedor: "",
        factura: "",
        quantity: "",
        costo: "",
        cost_sin_igv: "",
        porcentaje: "",
        price_venta: "",
        subtotal_venta: "",
        subtotal_costo: "",
        subtotal_ganancia: "",
      });
    },
    mtd_del_item: function (pos) {
      let temp = [];
      this.coti.detalle.forEach((element, index) => {
        if (index != pos) {
          temp.push(element);
        }
      });
      this.coti.detalle = temp;
      this.mtd_out_focus();
    },
    mtd_out_focus: function () {
      let sub_venta = 0,
        sub_costo = 0,
        sub_gana = 0;
      this.coti.detalle.forEach((element) => {
        let costo_sin_igv = element.costo > 0 ? element.costo / 1.18 : 0;
        element.cost_sin_igv = parseFloat(costo_sin_igv).toFixed(3);

        let por = parseFloat(element.porcentaje / 100);
        let precio_venta =
          element.porcentaje > 0
            ? parseFloat(costo_sin_igv * por) + parseFloat(costo_sin_igv)
            : 0;
        element.price_venta = parseFloat(precio_venta).toFixed(3);

        let venta =
          element.quantity > 0
            ? parseFloat(element.quantity) * parseFloat(precio_venta)
            : 0;
        let costo =
          element.quantity > 0
            ? parseFloat(element.quantity) * parseFloat(costo_sin_igv)
            : 0;
        let gana = parseFloat(venta - costo);
        element.subtotal_venta = parseFloat(venta).toFixed(2);
        element.subtotal_costo = parseFloat(costo).toFixed(2);
        element.subtotal_ganancia = parseFloat(gana).toFixed(2);
        sub_venta += parseFloat(element.subtotal_venta);
        sub_costo += parseFloat(element.subtotal_costo);
        sub_gana += parseFloat(element.subtotal_ganancia);
      });
      this.sub_gana = sub_gana;
      this.sub_venta = sub_venta;
      this.sub_costo = sub_costo;
    },
    mtd_save: function () {
      let url = this.action == "edit" ? "/edit" : "/store";
      this.post({
        url: this.$store.getters.get__url + "/" + this.prefix + url,
        token: this.$store.getters.get__token,
        params: {
          coti: this.coti,
          sub_venta: this.sub_venta,
          sub_costo: this.sub_costo,
          sub_gana: this.sub_gana,
          user: this.$store.getters.get__user_id,
          coti_id: this.coti_edit,
        },
      })
        .then((response) => {
          if (response.state == 0) {
            let msg =
              this.action == "edit"
                ? "COTIZACIÓN EDITADA CORRECTAMENTE"
                : "COTIZACIÓN EMITIDA CORRECTAMENTE!!";
            this.mtd_return_table();
            this.mtd_search_filter();
            bus.$emit("alert", {
              color: "success",
              message: msg,
            });
          } else {
            bus.$emit("alert", {
              color: "warning",
              message: response.message,
            });
          }
        })
        .catch((errors) => {
          bus.$emit("alert", {
            color: "danger",
            message: errors,
          });
        });
    },
    /** delete */
    mtd_delete: function (item) {
      this.modal_delete.boo = true;
      this.modal_delete.item = item;
    },
    close_delete: function () {
      this.modal_delete.boo = false;
    },
    mtd_commit: function () {
      this.post({
        url: this.$store.getters.get__url + "/" + this.prefix + "/destroy",
        token: this.$store.getters.get__token,
        params: {
          id: this.modal_delete.item.Id,
        },
      })
        .then((response) => {
          if (response.state == 0) {
            bus.$emit("alert", {
              color: "success",
              message: "ELIMINADO CORRECTAMENTE!!",
            });
            this.mtd_search_filter();
          } else if (response.state == 2) {
            bus.$emit("alert", {
              color: "warning",
              message: response.message,
            });
          } else {
            bus.$emit("alert", {
              color: "danger",
              message: response.message,
            });
          }
          this.close_delete();
        })
        .catch((errors) => {
          bus.$emit("alert", {
            color: "danger",
            message: errors,
          });
        });
    },
    /** view det */
    mtd_view_det: function (item) {
      console.log(item);
      this.modal_view.boo = true;
      this.modal_view.item = item.detail;
      this.modal_view.obs = item.observation;
      this.modal_view.enc = item.tecnico;
      this.modal_view.coti = item.coti;
    },
    close_view: function () {
      this.modal_view = {
        boo: false,
        item: [],
      };
    },
    /** reg num coti */
    mtd_add_coti: function (item) {
      this.modal_regcoti.boo = true;
      this.modal_regcoti.item = item;
    },
    close_regcoti: function () {
      this.modal_regcoti = {
        boo: false,
        item: [],
      };
    },
    mtd_commit_regcoti: function (num) {
      this.post({
        url: this.$store.getters.get__url + "/" + this.prefix + "/regcoti",
        token: this.$store.getters.get__token,
        params: {
          id: this.modal_regcoti.item.Id,
          num: num,
        },
      })
        .then((response) => {
          if (response.state == 0) {
            bus.$emit("alert", {
              color: "success",
              message: "REGISTRADO CORRECTAMENTE!!",
            });
            this.mtd_search_filter();
          } else {
            bus.$emit("alert", {
              color: "danger",
              message: response.message,
            });
          }
          this.close_regcoti();
        })
        .catch((errors) => {
          bus.$emit("alert", {
            color: "danger",
            message: errors,
          });
        });
    },
    /** OC */
    mtd_orden_compra: function (item) {
      this.view = "orden";
      this.orden_detail = item.detail;
      this.orden.quote_id = item.Id;
      this.orden.number = "";
      this.orden.guia = "";
      this.orden.date = this.hoy;
    },
    mtd_select_item: function (col) {
      if (this.action_selection.length === 0) {
        this.action_selection.push(col);
      } else {
        let verif = this.mtd_verif_exits_selection(col.id);
        if (verif == false) {
          col.disabled = false;
          this.action_selection.push(col);
        } else {
          let temp = [];
          this.action_selection.forEach((element) => {
            if (element.id != col.id) {
              element.disabled = false;
              temp.push(element);
            } else {
              element.disabled = true;
            }
          });
          this.action_selection = temp;
        }
      }
    },
    mtd_verif_exits_selection: function (id) {
      let boo = false;
      this.action_selection.forEach((element) => {
        if (element.id == id) {
          boo = true;
        }
      });
      return boo;
    },
    mtd_save_oc: function () {
      this.post({
        url: this.$store.getters.get__url + "/" + this.prefix + "/regoc",
        token: this.$store.getters.get__token,
        params: {
          orden: this.orden,
          detail: this.action_selection,
        },
      })
        .then((response) => {
          // console.log(response);
          if (response.state == 0) {
            bus.$emit("alert", {
              color: "success",
              message: "REGISTRADO CORRECTAMENTE!!",
            });
            this.mtd_search_filter();
          } else {
            bus.$emit("alert", {
              color: "danger",
              message: response.message,
            });
          }
          this.orden.quote_id = null;
          this.orden.number = "";
          this.orden.guia = "";
          this.orden.date = this.hoy;
          this.action_selection = [];
          this.mtd_return_table();
          this.mtd_search_filter();
        })
        .catch((errors) => {
          bus.$emit("alert", {
            color: "danger",
            message: errors,
          });
        });
    },
    /** excel */
    mtd_upload_excel: function () {
      this.modal_excel.boo = true;
    },
    close_upexcel: function () {
      this.modal_excel.boo = false;
    },
    mtd_commit_upexcel: function (res) {
      this.coti.detalle = res;
      this.close_upexcel();
      this.mtd_out_focus();
    },
    /** edit detail */
    mtd_edit: function (item) {
      // console.log(item);
      this.coti_edit = item.Id;
      this.action = "edit";
      this.view = "document";
      this.coti = {
        client: null,
        ticket: item.ticket,
        tecnico: item.tecnico,
        observation: item.observation,
        subtotal_venta: "",
        subtotal_costo: "",
        subtotal_ganancia: "",
        coti: item.coti,
        guia: "",
        detalle: item.detail,
      };
    },
    /** download */
    mtd_download: function () {
      const data = this.excel;
      const fileName = "cotizaciones";
      const typeExport = exportFromJson.types.xls;
      exportFromJson({
        data: data,
        fileName: fileName,
        exportType: typeExport,
      });
    },
    /** helpers */
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
