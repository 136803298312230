<template>
  <CModal
    :show.sync="boo_modal"
    :no-close-on-backdrop="true"
    :centered="false"
    title="Modal title 2"
    color="success"
  >
    <CCol lg="12" md="12" xs="12">
      <CInput type="file" v-model="file" label="Archivo" id="file" @change="mtd_change"  />
    </CCol>
    <template #header>
      <h6 class="modal-title">{{ title }}</h6>
      <CButtonClose @click="mtd_close" class="text-white" />
    </template>
    <template #footer>
      <CButton @click="mtd_close" color="danger">Cancelar</CButton>
      <CButton @click="mtd_commit" :disabled="cp_button" color="success"
        >Guardar</CButton
      >
    </template>
  </CModal>
</template>

<script>
import readXlsFile from "read-excel-file";

export default {
  name: "c-modal-view",
  data() {
    return {
      all: [],
      res:[],
      file:null,
    };
  },
  props: {
    title: String,
    boo_modal: Boolean,
    size: String,
  },
  computed: {
    cp_button() {
        if (this.file != null) {
          return false;
        }
      return true;
    },
  },
  methods: {
    mtd_close() {
      this.$emit("close_upexcel");
    },
    mtd_commit() {
      for (let index = 4; index < this.all.length; index++) {
        const element = this.all[index];
        if (element[3] == null) {
          break;
        } else {
          this.res.push({
            description: element[3],
            unit: element[4],
            proveedor: element[5],
            factura: "",
            quantity: element[7],
            costo: element[8],
            cost_sin_igv: "",
            porcentaje: element[10]*100,
            price_venta: "",
            subtotal_venta: "",
            subtotal_costo: "",
            subtotal_ganancia: "",
          });
        }
      }
      this.$emit("mtd_commit_upexcel",this.res);
      this.file=null;      
      this.all= [];
      this.res=[];
    },
    mtd_change: function(){
      const input = document.getElementById("file");
      readXlsFile(input.files[0]).then((rows) => {
        this.all = rows;
      });
    }
  },
};
</script>

<style lang="scss" scoped>
</style>