<template>
  <CModal
    :show.sync="boo_modal"
    :no-close-on-backdrop="true"
    :centered="false"
    title="Modal title 2"
    
    color="info"
  >
    <div class="row" v-if="item != undefined">
      <CCol lg="12" md="12" xs="12">
        <CInput
          label="N° Cotización"
          placeholder="N° Cotización"
          v-model="num"
        />
      </CCol>
    </div>
    <template #header>
      <h6 class="modal-title">{{ title }}</h6>
      <CButtonClose @click="mtd_close" class="text-white" />
    </template>
    <template #footer>
      <CButton @click="mtd_close" color="danger">Cancelar</CButton>
      <CButton @click="mtd_commit" :disabled="cp_button" color="success">Guardar</CButton>
    </template>
  </CModal>
</template>

<script>
export default {
  name: "c-modal-view",
  data() {
    return {
      num:""
    };
  },
  props: {
    title: String,
    boo_modal: Boolean,
    item: {},
    size: String,
  },
  computed: {
    cp_button(){
      if (this.num != null && this.num != "") {
        return false;
      }
      return true;
    }
  },
  methods: {
    mtd_close() {
      this.$emit("close_regcoti");
    },
    mtd_commit(){
      this.$emit("mtd_commit_regcoti",this.num);
      this.num="";
    }
  },
};
</script>

<style lang="scss" scoped>
</style>